import { Browser } from '@capacitor/browser';
import { Storage } from '@ionic/storage';
import {
    OAuth2AuthenticateOptions,
    OAuth2Client,
} from '@byteowls/capacitor-oauth2'
import { application } from './application'
import { Configuration } from '@/env';

export class auth extends EventTarget {
    App: application
    accessToken = ''
    accessTokenExpiration = 0
    private initialized = false
    private _isLogged = false
    public returnRoute: string | null = null
    public _loggedIn: Event = new Event('loggedIn')

    private storage: Storage
    constructor(App: application) {
        super()
        this.App = App
        this.storage = new Storage()
    }

    async init() {
        console.warn('Auth init')

        if (this.initialized) {
            return
        }

        await this.storage.create()

        if (await this.getAccessToken()) {
            this._isLogged = true
            await this.App.usuarios.refresh()
            this.redirectToReturnUrl()
            this.dispatchEvent(this._loggedIn)
        }

        this.initialized = true

    }
    get isLogged() {
        return this._isLogged
    }

    async login() {
        const oauth2Options: OAuth2AuthenticateOptions = {
            authorizationBaseUrl: Configuration.AUTH_AUTHORIZATION_BASE_URL,
            accessTokenEndpoint: Configuration.AUTH_ACCESS_TOKEN_ENDPOINT,
            scope: '',
            logsEnabled: true,
            web: {
                appId: Configuration.AUTH_WEB_APP_ID,
                redirectUrl: Configuration.AUTH_WEB_REDIRECT_URL,
                responseType: "token", // implicit flow
                accessTokenEndpoint: "", // clear the tokenEndpoint as we know that implicit flow gets the accessToken from the authorizationRequest
                windowOptions: "height=600,left=0,top=0"
            },
            android: {
                appId: 'native-appv5',
                responseType: 'token', // if you configured a android app in google dev console the value must be "code"
                redirectUrl: 'com.mutual.mirial:/', // package name from google dev console
                customHandlerClass: 'com.dribba.mirial.MirialOAuth2Handler', // optional, default: undefined
            },
            ios: {
                appId: 'native-appv5',
                responseType: 'token', // if you configured a ios app in google dev console the value must be "code"
                redirectUrl: 'com.mutual.mirial:/', // Bundle ID from google dev console
            },
        }
        try {
            const response = await OAuth2Client.authenticate(oauth2Options)

            // TODO: guardar token, refresh token, etc
            let accessToken = response['access_token']
            const expiresIn = 604800
            // this.refreshToken = response['refresh_token']

            if (!accessToken) {
                accessToken = response['authorization_response']['/access_token']
            }
            // expiresIn = 0; // response['authorization_response']['expires_in']
            this.setAccessToken(accessToken, expiresIn)

            // console.warn(response)

            // only if you include a resourceUrl protected user values are included in the response!
            // const oauthUserId = response["id"];
            // let name = response["name"];

            // go to backend
            this._isLogged = true
            await this.App.usuarios.refresh()
            this.redirectToReturnUrl()
            this.dispatchEvent(this._loggedIn)
        } catch (e) {
            // TODO: controlar errores
            console.error(e)
        }
    }

    // event onLoggedIn


    redirectToReturnUrl() {
        if (this.isLogged && this.App.router.currentRoute && this.App.router.currentRoute.fullPath === '/login') {
            if (this.returnRoute) {
                this.App.router.replace(this.returnRoute)
            } else {
                this.App.router.replace('/')
            }
        }
    }

    openEditProfile() {
        Browser.open(
            { url: Configuration.AUTH_EDIT_PROFILE_URL + '?access_token=' + this.accessToken }
            )
    }

    async setAccessToken(token: string, expiresIn: number) {
        this.accessToken = token

        // Persists the token in the localStorage
        // localStorage.setItem('accessToken', token)
        await this.storage.set('accessToken', token)

        // Converts the expiresIn to milliseconds and adds it to the current time
        const expirationTime = new Date().getTime() + expiresIn * 1000

        // Persists the expiration time in the localStorage
        // localStorage.setItem('accessTokenExpiration', expirationTime.toString())
        await this.storage.set('accessTokenExpiration', expirationTime.toString())
    }

    async getAccessToken(): Promise<boolean> {
        // Tries to retreive the token from the localStorage
        // const token = localStorage.getItem('accessToken')
        const token = await this.storage.get('accessToken')

        if (!token) {
            return false
        } else {
            this.accessToken = token
        }

        // const accessTokenExpiration = localStorage.getItem('accessTokenExpiration')
        const accessTokenExpiration = await this.storage.get('accessTokenExpiration')
        this.accessTokenExpiration = accessTokenExpiration ? parseInt(accessTokenExpiration) : 0

        // If accessToken is expired then invalidate accesstoken
        if (new Date().getTime() > this.accessTokenExpiration) {
            this.accessToken = ''
            this.accessTokenExpiration = 0
            return false
        }

        return true
    }

    async logout(){
        await this.storage.remove('accessToken')
        await this.storage.remove('accessTokenExpiration')
        this.accessToken = ''
        this.accessTokenExpiration = 0
        this._isLogged = false
        this.App.router.replace('/login')
        this.dispatchEvent(this._loggedIn)
        this.App.openUrl(Configuration.AUTH_LOGOUT_URL)
    }
}

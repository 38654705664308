<template>
    <IonHeader v-if="props.showHeader" class="ion-no-border">
        <IonToolbar>
            <IonButtons slot="start">
                <IonButton color="medium" @click="cancel">Cancelar</IonButton>
            </IonButtons>
            <IonTitle>
                Nueva Partida
            </IonTitle>
        </IonToolbar>
    </IonHeader>

    <IonContent>
        <CabeceraView>
            <IonGrid>
                <IonRow class="ion-justify-content-center">
                    <h3>Jugar contra otro usuario:</h3>
                </IonRow>
                <IonRow>
                    <IonCol size="12">
                        <ion-button expand="block" class="ion-text-wrap" @click="onBuscarUsuario">
                            <ion-icon slot="start" :icon="searchOutline"></ion-icon>
                            <IonLabel>
                                Buscar Mirialista o Email
                            </IonLabel>
                        </ion-button>
                        <ion-button expand="block" class="ion-text-wrap" @click="onUsuarioAleatorio">
                            <ion-icon slot="start" :icon="tabIconPartidas"></ion-icon>
                            Mirialista aleatorio
                        </ion-button>
                        <ion-button expand="block" v-if="false" class="ion-text-wrap">
                            <ion-icon slot="start" :icon="tabIconRanking"></ion-icon>
                            Seleccionar del ranking
                        </ion-button>
                    </IonCol>
                </IonRow>
            </IonGrid>
        </CabeceraView>

        <CabeceraView :background-height="0">
            <IonGrid>
                <IonRow class="ion-justify-content-center">
                    <h3>Otras modalidades de juego:</h3>
                </IonRow>
                <IonRow>
                    <IonCol size="12">
                        <ion-button expand="block" class="ion-text-wrap" @click="onModoIndividual">
                            <IonLabel>
                                Modo individual
                            </IonLabel>
                        </ion-button>
                    </IonCol>
                </IonRow>
            </IonGrid>
        </CabeceraView>
    </IonContent>
</template>
<script setup lang="ts">
import { App } from '@/services/application';
import { IonButtons, IonButton, IonCol, IonContent, IonGrid, IonHeader, IonIcon, IonItemDivider, IonRow, IonTitle, IonToolbar, modalController, IonLabel } from '@ionic/vue';
import tabIconIndividual from '@/assets/tab-icon-individual.svg';
import tabIconPartidas from '@/assets/tab-icon-partidas.svg';
import tabIconRanking from '@/assets/tab-icon-ranking.svg';
import { searchOutline } from 'ionicons/icons';
import { Usuario } from '@/models/Usuario';
import CabeceraView from './CabeceraView.vue';

const props = defineProps({
    showHeader: {
        type: Boolean,
        default: false
    }
})

const emit = defineEmits<{
    (e: 'nuevaPartidaConUsuario', usuario: Usuario): void,
    (e: 'nuevaPartidaConUsuarioAleatorio'): void,
    (e: 'modoIndividual'): void,
}>()
async function onBuscarUsuario() {
    const usuario = await App.usuarios.showBuscarUsuario()

    if (usuario) {
        if (await App.partidas.confirmarIniciarNuevaPartidaCon(usuario)) {
            emit('nuevaPartidaConUsuario', usuario)
        }
    }
}
async function onUsuarioAleatorio() {
    if (await App.partidas.confirmarIniciarNuevaPartidaCon(null)) {
        emit('nuevaPartidaConUsuarioAleatorio')
    }
}
function onModoIndividual(){
    emit('modoIndividual')
}
const cancel = () => modalController.dismiss(null, 'cancel');
</script>
<style scoped lang="scss"></style>
import { Partida } from '@/models/Partida'
import { application } from './application'
import PosicionesTablero from '@/json/PosicionesTablero.json'
import { Pregunta } from '@/models/Pregunta'
import { createApp } from 'vue'
import PreguntaView from '../views/components/PreguntaView.vue'
import PreguntaRespuestaView from '../views/components/PreguntaRespuestaView.vue'
import samples from '@/models/samples'
import { Categoria } from '@/models/Categoria'
import { modalController } from '@ionic/vue'

export class juego {
    App: application
    public PosicionesTablero = PosicionesTablero

    constructor(App: application) {
        this.App = App
    }

    async Activas(): Promise<Partida[]> {
        const result = await this.App.api.get('partidas/activas')
        return result.object
    }

    async ActualizarDados(idPartida: number, dados: number): Promise<Partida> {
        const result = await this.App.api.get(
            'partida/actualizar-dados' +
            '?idpartida=' + idPartida +
            '&dados=' + dados
        )

        return result.object
    }

    async ActualizarPosicion(idPartida: number, posicion: number): Promise<Partida> {
        const result = await this.App.api.get(
            'partida/actualizar-posicion' +
            '?idpartida=' + idPartida +
            '&posicion=' + posicion
        )

        return result.object
    }

    async NuevaPartida(idUsuario: number | null): Promise<Partida> {
        const result = await this.App.api.get(
            'partidas/nueva' +
            '?idoponente=' + (idUsuario ? idUsuario : '')
        )

        return result.object
    }
    async ContestarPreguntaPartida(idPartida: number, respuesta: number): Promise<Partida> {
        const result = await this.App.api.get(
            'partida/contestar-pregunta-partida' +
            '?idpartida=' + idPartida +
            '&respuesta=' + respuesta
        )

        return result.object
    }

    async ContestarPreguntaReto(idPartida: number, respuesta: number): Promise<Pregunta> {
        const result = await this.App.api.get(
            'partida/contestar-pregunta-reto' +
            '?idpartida=' + idPartida +
            '&respuesta=' + respuesta
        )

        return result.object
    }
    async SiguientePreguntaReto(idPartida: number): Promise<Pregunta> {
        const result = await this.App.api.get(
            'partida/siguiente-pregunta-reto' +
            '?idpartida=' + idPartida
        )

        return result.object
    }
    async MostrarRespuesta(respuesta: Pregunta | null, showDescanso = false, indiceReto: number | null = null): Promise<boolean> {
        if (!respuesta) {
            respuesta = samples.PreguntaRespuesta;
        }

        const modal = await modalController.create({
            component: PreguntaRespuestaView,
            canDismiss: true,
            cssClass: 'modal-fullscreen',
            animated: false,
            componentProps: {
                preguntaRespuesta: respuesta,
                showDescanso: showDescanso,
                indiceReto: indiceReto,
                onClickContinuar: () => {
                    modal.canDismiss = true;
                    modal.dismiss(true, 'confirm')
                },
                onClickDescansar: () => {
                    modal.canDismiss = true;
                    modal.dismiss(false, 'cancel')
                }
            }
        });

        modal.present();

        const { data, role } = await modal.onDidDismiss();

        return data;
    }
    async MostrarPregunta(pregunta: Pregunta | null, indiceReto: number | null = null): Promise<number> {

        // TODO: treure
        if (!pregunta) {
            pregunta = samples.Pregunta;
        }
        const modal = await modalController.create({
            component: PreguntaView,
            canDismiss: false,
            cssClass: 'modal-fullscreen',
            animated: false,
            componentProps: {
                pregunta: pregunta,
                indiceReto: indiceReto,
                onClickRespuesta: async (respuesta: number) => {
                    modal.canDismiss = true;
                    modal.dismiss(respuesta, 'confirm')
                },
                onTiempoAgotado: () => {
                    modal.canDismiss = true;
                    modal.dismiss(-1, 'cancel')
                }
            }
        });

        modal.present();

        const { data, role } = await modal.onDidDismiss();

        if (role === 'confirm') {
            return data;
        }

        return -1;
    }

    async Categorias(): Promise<Categoria[]> {
        const apiResult = await this.App.api.get('partida/colores')
        const colores = apiResult.object as string[]
        const result = new Array<Categoria>()

        colores.forEach((item, index) => {
            if (index == 0) return
            if (index == 3) return
            if (index == 9) return
            if (index == 10) return
            result.push(new Categoria(index, item))
        });

        return result
    }

    async Pregunta(color: number): Promise<Pregunta> {
        const result = await this.App.api.get('partida/pregunta?color=' + color)
        return result.object
    }

    async ContestarPregunta(idPregunta: number, respuesta: number): Promise<Pregunta> {
        const result = await this.App.api.get(
            'partida/contestar-pregunta' +
            '?idpregunta=' + idPregunta +
            '&respuesta=' + respuesta
        )

        return result.object
    }
}

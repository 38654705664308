import { Especialidad } from "./Especialidad";
import { RankingUsuario } from "./RankingUsuario";
import { NivelUsuario } from "./NivelUsuario";

export class Usuario{
    /**
     * Id de usuario
     */
    public id = 0;
    /**
     * Nombre de usuario
     */
    public usuario = "";
    /**
     * Especialidad del usuario
     */
    public especialidad!: Especialidad;
    /**
     * Hora de última conexión
     */
    public ultimaconexion!: string;
    /**
     * Ranking del usuario
     */
    public ranking!: RankingUsuario;

    /**
     * Notificaciones pendientes de leer
     */
    public notificaciones = 0;

    /**
     * Mensajes pendientes de leer
     */
    public mensajes = "0";
    /**
     * Feeds pendientes de leer
     */
    public feed = 0;
    /**
     * Invitaciones pendientes de leer
     */
    public invitaciones = "0";
    /**
     * Partidas pendientes
     */
    public partidas = "0";
    /**
     * @var NivelUsuario
     */
    public nivel!: NivelUsuario;
}
import { application } from "./application";
import { Chat } from "@/models/Chat";
import { ChatMensaje } from "@/models/ChatMensaje";

export class chat {
    App: application
    public idusuario = 0

    constructor(App: application) {
        this.App = App
    }

    async MisChats(): Promise<Chat[]> {
        const apiResult = await this.App.api.get("chat/mis-chats")
        const result = Object.assign(new Array<Chat>(), apiResult.object) as Chat[]

        return result
    }

    async Cargar(idusario: number, cantidad: number, desdeid?: number | null, hastaid?: number | null): Promise<ChatMensaje[]> {
        const apiResult = await this.App.api.get(
            'chat/cargar' +
            '?idusuario=' + idusario +
            '&cantidad=' + cantidad +
            (desdeid ? '&desdeid=' + desdeid : '') +
            (hastaid ? '&hastaid=' + hastaid : '')
        )
        const result = Object.assign(new Array<ChatMensaje>(), apiResult.object) as ChatMensaje[]

        return result
    }

    async Enviar(iddestinatario: number, texto: string): Promise<ChatMensaje> {
        const apiResult = await this.App.api.get(
            'chat/enviar?iddestinatario=' + iddestinatario + '&texto=' + texto
        )
        const result = Object.assign(new ChatMensaje(), apiResult.object) as ChatMensaje

        return result
    }
}
import { application } from "./application";
import { Ranking } from "@/models/Ranking";

export class ranking{
    App: application
    public idusuario = 0

    constructor(App: application){
        this.App = App
    }

    async anual(): Promise<Ranking>{
        const apiResult = await this.App.api.get("ranking/anual")
        const result = Object.assign(new Ranking(), apiResult.object) as Ranking

        return result
    }
    async mensual(): Promise<Ranking>{
        const apiResult = await this.App.api.get("ranking/mensual")
        const result = Object.assign(new Ranking(), apiResult.object) as Ranking

        return result
    }
    async semanal(): Promise<Ranking>{
        const apiResult = await this.App.api.get("ranking/semanal")
        const result = Object.assign(new Ranking(), apiResult.object) as Ranking

        return result
    }
}
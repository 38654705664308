import { Banner } from "@/models/Banner";
import { application } from "./application";

export class banners {
    App: application
    public idusuario = 0
    private _banner!: Banner

    constructor(App: application) {
        this.App = App

        this.App.auth.addEventListener('loggedIn', async () => {
            await this.refresh()
        })
    }
    get banner() {
        return this._banner
    }
    async cargar(): Promise<Banner> {
        const apiResult = await this.App.api.get("banners/cargar")
        const result = Object.assign(new Banner(), apiResult.object) as Banner

        return result
    }

    private refreshTimeout: any = null
    async refresh() {
        if (this.refreshTimeout) {
            clearTimeout(this.refreshTimeout)
        }

        if (this.App.auth.isLogged) {
            this._banner = await this.cargar()
        }

        this.refreshTimeout = setTimeout(() => {
            this.refresh()
        }, 1000 * 30)
    }

}
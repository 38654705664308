export class ApiResult {
    public status = false
    public message = ""
    public object: any
    public code = 0

    constructor(status: boolean, message: string, object: any, code: number) {
        this.status = status
        this.message = message
        this.object = object
        this.code = code
    }
}
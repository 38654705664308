import { Browser } from '@capacitor/browser';
import { api } from "./api";
import { auth } from "./auth";
import { partidas } from "./partidas";
import { usuarios } from "./usuarios";
import { ranking } from "./ranking";
import { reactive } from 'vue';
import { useRouter } from 'vue-router';
import { juego } from "./juego";
import numeral from "numeral";
import { chat } from "./chat";
import { estadisticas } from "./estadisticas";
import { alertController, isPlatform, toastController } from '@ionic/vue';
import Notifications from './notifications';
import { Notificacion } from '@/models/Notificacion';
import { banners } from './banners';

export class application {
    public api: api = new api(this)
    public auth: auth = new auth(this)
    public banners: banners = new banners(this)
    public chat: chat = new chat(this)
    public partidas: partidas = new partidas(this)
    public juego: juego = new juego(this)
    public usuarios: usuarios = new usuarios(this)
    public ranking: ranking = new ranking(this)
    public estadisticas: estadisticas = new estadisticas(this)
    public notifications: Notifications = new Notifications(this)

    public router!: any

    public userRefreshTimer: any = null

    public UIModal!: any;

    constructor() {
        console.warn("App init")
    }

    init() {
        this.api.init()
        // this.auth.init()

        this.userRefreshTimer = setInterval(() => {
            if (this.auth.isLogged == true) {
                this.usuarios.refresh()
            }
        }, 1000 * 15)

        numeral.register('locale', 'es', {
            delimiters: {
                thousands: '.',
                decimal: ','
            },
            abbreviations: {
                thousand: 'k',
                million: 'm',
                billion: 'b',
                trillion: 't'
            },
            ordinal: function (number) {
                return number === 1 ? 'er' : 'ème';
            },
            currency: {
                symbol: '€'
            }
        });
    }

    async routerPush(url: string) {
        if (!this.router) {
            this.router = useRouter()
        }

        await this.router.push(url)
    }

    hideModal() {
        this.UIModal.$el.dismiss();

        // TODO: destroy content
    }
    async showModal(component: any): Promise<void> {
        const mountedComponent = component.mount(this.UIModal.$el);

        await this.UIModal.$el.present();
    }

    formatNumber(value: number) {
        numeral.locale('es');
        return numeral(value).format('0,0');
    }
    formatPercentage(value: number) {
        numeral.locale('es');
        return numeral(value * 100).format('0,0.') + "%";
    }

    openUrl(url: string) {
        Browser.open({ url: url });
    }

    async showToast(message: string, color = "primary") {
        const toast = await toastController.create({
            message: message,
            duration: 3000,
            color: color,
            buttons: [
                {
                    text: 'Dismiss',
                    role: 'cancel',
                    handler: () => {
                        // this.handlerMessage = 'Dismiss clicked';
                    },
                },
            ],
        });

        await toast.present();
    }

    async showNotificacion(notificacion: Notificacion): Promise<boolean> {
        let result = false

        this.notifications.MarcarLeida(notificacion.id)

        // Notificaciones de tipo 1 a 9 no se muestran
        if( notificacion.tipo < 10 ) return true;

        const message = notificacion.texto

        const alert = await alertController.create({
            header: 'Notificación',
            message: message,
            buttons: [
                {
                    text: 'Ok',
                    handler: () => {
                        result = true
                    }
                }
            ]
        });

        alert.present();
        await alert.onDidDismiss();

        return result
    }

    /**
     * Platform Identifier
     * @returns 1 for iOS, 2 for Android, 3 for Web
     */
    getPlatformCode() {
        const platform =
            isPlatform('mobileweb') ? "3" :
                isPlatform('ios') ? "1" : isPlatform('android') ? "2" : "3";

        return platform;
    }
}

/* Singleton */
export const App = reactive(new application());


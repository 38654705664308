<template>
    <IonHeader>
        <IonToolbar>
            <IonButtons slot="start">
                <IonButton color="medium" @click="cancel">Cancel</IonButton>
            </IonButtons>
            <IonTitle>
                Buscar usuario
            </IonTitle>
        </IonToolbar>
    </IonHeader>
    <ion-content>
        <ion-searchbar placeholder="Buscar" :debounce="500" @ionChange="handleInput($event)"></ion-searchbar>
        <ion-list>
            <ion-item v-for="usuario in data.results" :key="usuario.id" @click="confirm(usuario)">
                <ion-grid>
                    <ion-row>
                        <ion-col v-if="false" size="2">
                            {{ App.formatNumber(usuario.ranking.ranking) }}
                        </ion-col>
                        <ion-col>
                            <span class="usuario">
                                {{ usuario.usuario }}<br>
                            </span>
                            <span class="especialidad">
                                {{ usuario.especialidad.nombre }}
                            </span>
                        </ion-col>
                        <ion-col v-if="false" size="auto" class="ion-text-end">
                            {{ App.formatNumber(usuario.ranking.puntos) }}
                        </ion-col>
                        <ion-col size="auto" class="ion-text-end">
                            <NivelIconView :nivel="usuario.nivel.nivel"></NivelIconView>
                        </ion-col>
                    </ion-row>
                </ion-grid>
            </ion-item>
        </ion-list>
    </ion-content>
</template>
<script setup lang="ts">
import { Usuario } from '@/models/Usuario';
import { IonSearchbar, IonList, IonContent, IonItem, IonGrid, IonCol, IonRow, modalController, IonHeader, IonToolbar, IonButton, IonButtons, IonTitle } from '@ionic/vue'
import { reactive } from 'vue';
import { App } from '@/services/application';
import NivelIconView from './NivelIconView.vue';

const data = reactive({
    results: [] as Usuario[]
})

const emit = defineEmits<{
    (e: 'click', usuario: Usuario): void
}>()

async function handleInput(event: any) {
    const query = event.target.value.toLowerCase();

    if (query.length < 3) {
        data.results = [];
        return;
    }

    data.results = await App.usuarios.buscar(query)
}

const cancel = () => modalController.dismiss(null, 'cancel');
const confirm = (usuario: Usuario) => {
    emit('click', usuario);
    // modalController.dismiss(usuario, 'confirm');
}
</script>

<style scoped lang="scss">
ion-item:nth-of-type(even) {
    &::part(native) {
        background-color: var(--mirial-color-lightest-blue);
    }
}

.usuario {
    color: var(--mirial-color-blue);
}

.especialidad {
    color: var(--mirial-color-light-blue);
}
</style>
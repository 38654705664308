<template>
    <ion-header v-if="true">
        <IonToolbar>
            <IonTitle v-if="props.indiceReto">
                Reto: {{ props.indiceReto }} de 7
            </IonTitle>
            <IonTitle v-else>
                Respuesta
            </IonTitle>
        </IonToolbar>
    </ion-header>
    <ion-content v-if="props.preguntaRespuesta">

        <h2 class="ion-padding ion-text-center" v-if="mensajeFormateado">
            <p v-html="mensajeFormateado.before"></p>
            <ion-button expand="block" class="ion-text-wrap" v-if="mensajeFormateado.diamante">
                <p>
                    {{ mensajeFormateado.diamante }}
                </p>
            </ion-button>
            <p v-if="mensajeFormateado.after" v-html="mensajeFormateado.after"></p>
        </h2>


        <!-- ENUNCIADO -->
        <ion-accordion-group ref="accordionGroup" style="margin-bottom: 25px">
            <ion-accordion>
                <!-- color="light" -->
                <ion-item slot="header" class="item-pregunta">
                    <ion-label><strong>Enunciado:</strong></ion-label>
                </ion-item>
                <div class="ion-padding" slot="content">
                    {{ props.preguntaRespuesta.texto }}
                </div>
            </ion-accordion>
        </ion-accordion-group>

        <!-- RESPUESTAS -->
        <div v-for="respuesta in props.preguntaRespuesta.respuestas" :key="respuesta.codigo">
            <IonItem @click="toggleRespuesta(respuesta.codigo)"
                :class="'item-respuesta ' + (respuesta.correcta ? 'correcta' : '') + (respuesta.mirespuesta && respuesta.incorrecta ? 'incorrecta' : '')">
                <ion-label class="ion-text-wrap" style="text-overflow: unset;">{{ respuesta.texto }}</ion-label>
                <IonIcon :icon="respuesta.codigo == data.respuestaAbierta ? removeOutline : addOutline" slot="end"
                    color="primary"></IonIcon>
            </IonItem>
            <div class="ion-padding" v-if="data.respuestaAbierta === respuesta.codigo">
                <!-- <p>Explicación:</p> -->
                <p style="margin-top:0">
                    {{ respuesta.explicacion }}
                </p>
                <p style="font-size: small; text-align: center; font-weight: bold;">* Este texto se ha generado mediante IA y puede
                    contener errores.</p>
            </div>
        </div>

        <ion-list v-if="false">
            <ion-button expand="block" class="ion-text-wrap" v-for="respuesta in props.preguntaRespuesta.respuestas"
                :class="respuesta.correcta ? 'correcta' : '' + respuesta.mirespuesta && respuesta.incorrecta ? 'incorrecta' : ''"
                :key="respuesta.codigo">
                <p>
                    {{ respuesta.texto }}
                </p>
            </ion-button>
        </ion-list>
    </ion-content>
    <IonFooter>
        <!-- <IonToolbar class="color-transparent"> -->
        <ion-list>
            <ion-button expand="block" class="ion-text-wrap" @click="emit('clickContinuar')">
                <p>
                    Continuar
                </p>
            </ion-button>

            <ion-button v-if="props.showDescanso" expand="block" class="ion-text-wrap" color="danger"
                @click="emit('clickDescansar')">
                <p>
                    Uff... descanso
                </p>
            </ion-button>
        </ion-list>
        <!-- </IonToolbar> -->
    </IonFooter>
</template>

<script setup lang="ts">
import { Pregunta } from '@/models/Pregunta';
import { IonHeader, IonContent, IonList, IonButton, IonToolbar, IonTitle, IonIcon, IonAccordionGroup, IonAccordion, IonItem, IonLabel, IonFooter } from '@ionic/vue';
import { onMounted, computed, reactive } from 'vue';
import { addOutline, removeOutline } from 'ionicons/icons';


const props = defineProps({
    preguntaRespuesta: Pregunta,
    indiceReto: Number,
    showDescanso: {
        type: Boolean,
        default: false
    }
})

const data = reactive({
    respuestaAbierta: null as any
})

// Format message returning 3 parts: before, diamante and after
const mensajeFormateado = computed(() => {
    if (!props.preguntaRespuesta) return null
    if (!props.preguntaRespuesta.mensajerespuesta) return null

    // Search for ##<any texd>## in the message
    const diamante = props.preguntaRespuesta.mensajerespuesta.match(/##(.*)##/)?.[1] || ''

    // Get Before and after
    let [before, after] = props.preguntaRespuesta.mensajerespuesta.split(`##${diamante}##`)

    // Replace \\n with <br>
    if (before) before = before.replace('\\n', '<br>')
    if (after) after = after.replace('\\n', '')
    if (before) before = before.replace('\\n', '<br>')
    if (after) after = after.replace('\\n', '')

    // Return the 3 parts
    return {
        before,
        diamante,
        after
    }
})
const emit = defineEmits<{
    (e: 'clickContinuar'): void,
    (e: 'clickDescansar'): void,
}>()

onMounted(() => {
    //
})

function toggleRespuesta(respuesta: any) {
    data.respuestaAbierta = data.respuestaAbierta == respuesta ? null : respuesta
}
</script>

<style scoped lang="scss">
ion-list,
ion-item {
    --background: var(--mirial-color-light-gray) !important;
    background: var(--mirial-color-light-gray) !important;
}

.item-respuesta {
    // border-top: 1px solid var(--mirial-color-blue);

    // Last one with border bottom
    ion-accordion:last-child & {
        // border-bottom: 1px solid var(--mirial-color-blue);
    }
}

.item-pregunta {
    // border-top: 1px solid var(--mirial-color-blue);
}

ion-button {
    height: auto;
}

/* .item-respuesta{
    --background: var(--mirial-color-blue) !important;
    background: var(--mirial-color-blue) !important;
} */
.correcta {
    --background: var(--mirial-color-green) !important;
    background-color: var(--mirial-color-green) !important;
}

.incorrecta {
    --background: var(--mirial-color-red) !important;
    background-color: var(--mirial-color-red) !important;
}

h2 p {
    margin-block-start: 2px;
    margin-block-end: 2px;
}

h2 ion-button p {
    margin-block-start: 1em;
    margin-block-end: 1em;
}
</style>
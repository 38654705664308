// import { PushNotifications } from '@capacitor/push-notifications';
import { application } from './application';
import { initializeApp, FirebaseOptions, FirebaseApp } from 'firebase/app';
import { getDatabase, push, ref, set } from "firebase/database";
import { FirebaseMessaging, GetTokenResult, PermissionStatus } from '@capacitor-firebase/messaging';

export default class Notifications {
    private App: application;
    private pushToken = null as string | null;
    private granted = false;
    private FirebaseApp: FirebaseApp | null = null;

    public constructor(App: application) {
        this.App = App;
        // this.init();

        this.App.auth.addEventListener('loggedIn', (e) => {
            if (App.auth.isLogged) {
                this.init()
            }
        });
    }
    public async init() {
        if (this.App.auth.isLogged == false) return
        if (this.pushToken !== null) return

        this.initializeFirebaseApp()
        await this.register();
    }

    async register() {

        if (await this.checkPermissions() == 'prompt') {
            console.warn('Requesting push permissions');
            this.granted = false
            await this.requestPermissions();
        }

        if (await this.checkPermissions() == 'granted') {
            console.warn('Push permissions granted');
            this.granted = true;
            this.pushToken = await this.getToken();
            console.warn('FCM token', this.pushToken);
            this.sendTokenToServer();
        } else {
            console.error('User denied push permissions!');
            return;
        }

        // const token = await this.getToken();
        // console.warn('FCM token', token);


        // let permStatus = await PushNotifications.checkPermissions();

        // if (permStatus.receive === 'prompt') {
        //     permStatus = await PushNotifications.requestPermissions();
        // }

        // if (permStatus.receive !== 'granted') {
        //     console.error('User denied push permissions!');
        //     return;
        // }

        // await this.registerEvents();
        // await PushNotifications.register();
    }

    async registerEvents_old() {
        // On success, we should be able to receive notifications
        // await PushNotifications.addListener('registration',
        //     (token: { value: string }) => {
        //         console.log('Push registration success, token: ' + token.value);
        //         this.pushToken = token.value;
        //     }
        // );

        // Some issue with our setup and push will not work
        // await PushNotifications.addListener('registrationError',
        //     (error: any) => {
        //         console.log('Error on registration: ' + JSON.stringify(error));
        //     }
        // );

        // Show us the notification payload if the app is open on our device
        // await PushNotifications.addListener('pushNotificationReceived',
        //     (notification: any) => {
        //         console.log('Push received: ' + JSON.stringify(notification));
        //     }
        // );

        // Method called when tapping on a notification
        // await PushNotifications.addListener('pushNotificationActionPerformed',
        //     (notification: any) => {
        //         console.log('Push action performed: ' + JSON.stringify(notification));
        //     }
        // );
    }

    // getDeliveredNotifications = async () => {
    //     const notificationList = await PushNotifications.getDeliveredNotifications();
    //     console.log('delivered notifications', notificationList);
    // }

    async MarcarLeida(idNotificacion: number) {
        const result = await this.App.api.get(
            'notificaciones/leer' +
            '?idnotificacion=' + idNotificacion
        )

        return result.object
    }

    initializeFirebaseApp() {
        if (this.FirebaseApp !== null) return this.FirebaseApp;

        const firebaseConfig = {
            apiKey: "AIzaSyDfhrfmnFtwNnglza_9vB64BQ1fZucRrww",
            authDomain: "mirial-fb.firebaseapp.com",
            databaseURL: "https://mirial-fb.firebaseio.com",
            projectId: "mirial-fb",
            storageBucket: "mirial-fb.appspot.com",
            messagingSenderId: "566759018561",
            appId: "1:566759018561:web:11120ba8a24c9a8e0a36c0"
        };

        // Initialize Firebase
        this.FirebaseApp = initializeApp(firebaseConfig);

        return this.FirebaseApp;
    }

    async sendTokenToServer() {
        if (!this.pushToken) {
            console.error('No push token available!');
            return;
        }

        // // DatabaseReference ref = FirebaseDatabase.instance.reference();
        // // var value = {'userid': userid, 'type': 'register', 'token': token};
        // // ref.child("requests").push().set(value);
        // const firebaseConfig = {} as FirebaseOptions;

        // firebaseConfig.databaseURL = "https://mirial-fb.firebaseio.com";
        // firebaseConfig.projectId = "mirial-fb";
        // firebaseConfig.apiKey = "AIzaSyCoayRrexjuGl6h1U5_QJBB76RaEH1axaM";
        // firebaseConfig.storageBucket = "mirial-fb.appspot.com";

        // const app = initializeApp(firebaseConfig);
        if( this.FirebaseApp === null ) return;

        const database = getDatabase(this.FirebaseApp);

        const value = {
            'userid': this.App.usuarios.Me.id,
            'type': 'register',
            'token': this.pushToken
        };

        push(ref(database, 'requests'), value);

        console.warn('Push token sent to server!');

    }

    async checkPermissions() {
        const result = await FirebaseMessaging.checkPermissions();
        return result.receive;
    }

    async requestPermissions() {
        const result = await FirebaseMessaging.requestPermissions();
        return result.receive;
    }

    async getToken() {
        let result: GetTokenResult | null = null;

        if (this.App.getPlatformCode() == '3') {
            try {
                result = await FirebaseMessaging.getToken({
                    vapidKey: 'BHwwW0pmtgG0THTaaQvZb8x0VIx3lNouFAajIDaBRf6wjSMrT8K8sUzX2dChxowa1Tt0i1gR5UbQSZwWBhXFCUA'
                });
            } catch (e) {
                console.error(e)
            }
        } else {
            result = await FirebaseMessaging.getToken();
        }

        if (result)
            return result.token;

        return null;
    }

    async deleteToken() {
        await FirebaseMessaging.deleteToken();
    }
}
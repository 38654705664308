import { Notificacion } from "./Notificacion";
import { Pregunta } from "./Pregunta";
import { Reto } from "./Reto";
import { Usuario } from "./Usuario";

export class Partida {
    /**
     * Id de partida
     */
    public id = 0;
    /**
     * Aceptada por el oponente
     */
    public aceptada = false;
    /**
     * Pendiente que yo acepte la invitacion
     */
    public pendienteAceptar = false;
    /**
     * Partida acabada
     */
    public finalizada = false;
    /**
     * Valor de los últimos dados tirados (1..6)
     * - Dados = 0 -> No se han tirado los dados
     * - Dados <> 0 -> Se han tirado los dados y se han obtenido las posiciones posibles
     */
    public dados = 0;
    /**
     * True si es mi turno, false si es del oponente
     * (Caso especial, cuando es reto-TODO)
     */
    public miTurno = false;
    /**
     * He ganado la partida
     */
    public heGanado = false;
    /**
     * Object con los diamantes ganados por mi en esta partida (ie: { "1": 1; "2": 0, ... , "8": 0 })
     */
    public diamantes = { "1": 0, "2": 0, "4": 0, "5": 0, "6": 0, "7": 0, "8": 0 };
    /**
     * Object con los diamantes ganados por el oponente en esta partida (ie: { "1": 1; "2": 0, ... , "8": 0 })
     */
    public diamantesOponente = { "1": 0, "2": 0, "4": 0, "5": 0, "6": 0, "7": 0, "8": 0 };
    /**
     * Mi posición en el tablero
     */
    public posicion = 0;
    /**
     * Mis posibles posiciones en el tablero con los dados tirados
     */
    public posiblesPosiciones!: PartidaPosicion[] | null;
    /**
     * La posición del oponente en el tablero
     */
    public posicionOponente = 0;
    /**
     * Mi oponente
     */
    public oponente!: Usuario;
    /**
     * Pregunta lanzada
     */
    public pregunta!: Pregunta;

    /**
     * Reto en curso
     */
    public reto!: Reto | null;

    /**
     * Ultima pregunta con sus respuestas
     * @var \common\jsonModels\Pregunta
     */
    public ultimaPregunta!: Pregunta | null;

    /**
     * Notificaciones
     */
    public notificaciones!: Notificacion[];

    /**
     * Mensajes pendientes
     */
    public mensajes = 0;
}
export class PartidaPosicion {
    /**
     * Posicion en el tablero
     */
    public posicion = 0;
    /**
     * IdColor de la posicion
     */
    public idcolor = 0;
    /**
     * Es diamante
     */
    public esdiamante = false;
    /**
     * Descripción de la posición
     */
    public texto = "";
}

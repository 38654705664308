/* Configuration */
export const Configuration = {
    API_URL: process.env.VUE_APP_API_URL as string,
    API_DEBUG: process.env.VUE_APP_API_DEBUG as string === "true",

    AUTH_AUTHORIZATION_BASE_URL: process.env.VUE_APP_AUTH_AUTHORIZATION_BASE_URL as string,
    AUTH_LOGOUT_URL: process.env.VUE_APP_AUTH_LOGOUT_URL as string,
    AUTH_ACCESS_TOKEN_ENDPOINT: process.env.VUE_APP_AUTH_ACCESS_TOKEN_ENDPOINT as string,
    AUTH_EDIT_PROFILE_URL: process.env.VUE_APP_AUTH_EDIT_PROFILE_URL as string,

    AUTH_WEB_APP_ID: process.env.VUE_APP_AUTH_WEB_APP_ID as string,
    AUTH_WEB_REDIRECT_URL: process.env.VUE_APP_AUTH_WEB_REDIRECT_URL as string,
};

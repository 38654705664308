import { application } from './application';
import { Configuration } from '@/env';
import { ApiResult } from '@/models/ApiResult';
import axios from 'axios';

export class api {
    public apiUrl: string | null = null
    public apiDebug = false
    App: application
    private timeout = 15000

    public extra = '' // '?XDEBUG_SESSION_START'

    constructor(App: application) {
        this.App = App
        this.apiUrl = Configuration.API_URL
        this.apiDebug = Configuration.API_DEBUG
    }

    init() {
        // console.warn("")
    }
    async post(url: string, data: any): Promise<ApiResult> {
        const headers = this.getHeaders();

        try {
            const response = await axios.post(
                this.apiUrl + url + this.extra,
                JSON.stringify(data),
                {
                    headers: headers,
                    // withCredentials: true,
                    timeout: this.timeout
                }
            );

            if( response.data.status == false ){
                // REplace \n with <br>
                response.data.message = response.data.message.replace("\\n", "<br />");
                this.App.showToast(response.data.message, 'danger')
            }

            return response.data;
        } catch (e) {
            console.error(e);

            // Get status
            const status = (<any>e).response.status;

            // If status==401 then logout
            if (status == 401) {
                this.App.auth.logout();
                return new ApiResult(false, 'Unauthorized', null, 401);
            } else
                throw e;
        }

    }
    async get(url: string): Promise<ApiResult> {
        const headers = this.getHeaders();

        try {
            const response = await axios(
                this.apiUrl + url + this.extra, {
                method: 'GET',
                headers: headers,
                // withCredentials: true,
                timeout: this.timeout
            }
            );

            if( response.data.status == false ){
                // REplace \n with <br>
                response.data.message = response.data.message.replace("\\n", "<br />");
                this.App.showToast(response.data.message, 'danger')
            }

            return response.data;
        } catch (e) {
            console.error(e);

            // Get status
            const status = (<any>e).response.status;

            // If status==401 then logout
            if (status == 401) {
                this.App.auth.logout();
                return new ApiResult(false, 'Unauthorized', null, 401);
            } else
                throw e;
        }
    }

    getHeaders() {
        const headers = {
            'content-type': 'application/json',
            'Authorization': 'Bearer ' + this.App.auth.accessToken,
            'x-platform': this.App.getPlatformCode(),
            "x-version": process.env.VUE_APP_VERSION,
        }

        return headers
    }
}
import { reactive } from 'vue'
import { Partida } from '../Partida'
import { Pregunta } from '../Pregunta'
import { Usuario } from '../Usuario'
import { Ranking } from '../Ranking'
import { ChatMensaje } from '../ChatMensaje'
import { Chat } from '../Chat'
import PartidaSample from './Partida.json'
import PartidasSample from './Partidas.json'
import PreguntaSample from './Pregunta.json'
import PreguntaImagenSample from './PreguntaImagen.json'
import UsuarioSample from './Usuario.json'
import RankingSample from './Ranking.json'
import Chats from './Chats.json'
import ChatMensajesSample from './ChatMensajes.json'
import PreguntaRespuestaSample from './PreguntaRespuesta.json'
import ColoresTextosSample from './ColoresTextos.json'
import BannerSample from './Banner.json'
import { Banner } from '../Banner'
export class Samples {
    public Partida = Object.assign(new Partida(), PartidaSample) as Partida
    public Partidas = Object.assign(new Array<Partida>, PartidasSample) as Array<Partida>
    public Pregunta = Object.assign(new Pregunta(), PreguntaSample) as Pregunta
    public PreguntaImagen = Object.assign(new Pregunta(), PreguntaImagenSample) as Pregunta
    public PreguntaRespuesta = Object.assign(new Pregunta(), PreguntaRespuestaSample) as Pregunta

    public Usuario = Object.assign(new Usuario(), UsuarioSample) as Usuario
    public Ranking = Object.assign(new Ranking(), RankingSample) as Ranking
    public ChatMensajes = Object.assign(new Array<ChatMensaje>(), ChatMensajesSample) as Array<ChatMensaje>
    public Chats = Object.assign(new Array<Chat>(), Chats) as Array<Chat>
    public ColoresTextos = Object.assign(new Array<string>(), ColoresTextosSample) as Array<string>

    public Banner = Object.assign(new Banner(), BannerSample) as Banner
}

const samples = reactive(new Samples())
export default samples


<template>
    <div class="header" :style="['padding-top: ' + props.marginTop + 'px', 'margin-bottom: ' + props.marginBottom + 'px']">
        <div class="content">
            <slot></slot>
        </div>
        <div class="background" :style="'height: ' + props.backgroundHeight + 'px;'"></div>
    </div>
</template>
<script setup lang="ts">
import { IonList, IonLabel, IonItemDivider, IonButton, IonItemGroup, IonItem, IonGrid, IonCol, IonRow, IonIcon } from '@ionic/vue';

const props = defineProps({
    backgroundHeight: {
        type: Number,
        default: 125
    },
    marginTop: {
        type: Number,
        default: 15
    },
    marginBottom: {
        type: Number,
        default: 15
    }
})
</script>

<style scoped lang="scss">
.header {
    position: relative;
}

.background {
    position: absolute;
    top: 0;
    left: 0;
    background-color: var(--mirial-color-blue);
    width: 100%;
    // height: 350px;
}

.content {
    position: relative;
    background-color: White;
    z-index: 1;
    border-radius: 10px;
    // height: 150px;
    // padding-bottom: 15px;
    margin: 15px;
    margin-top: 0;
    margin-bottom: 0;
    // margin-top: 30px;
    color: var(--mirial-color-blue);
}
</style>
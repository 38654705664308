import { Estadistica } from "@/models/Estadistica";
import { application } from "./application";

export class estadisticas{
    App: application
    public idusuario = 0

    constructor(App: application){
        this.App = App
    }

    async MisEstadisticas(): Promise<Estadistica[]>{
        const apiResult = await this.App.api.get("estadisticas/mis")
        const result = Object.assign(new Array<Estadistica>(), apiResult.object) as Estadistica[]
        return result
    }

}
import { RankingUsuario } from "./RankingUsuario";

export class Ranking {
    /**
     * Nombre del ranking
     */
    public titulo = "";
    /**
     * Informacion sobre el ranking
     */
    public subtitulo = "";
    /**
     * Usuarios
     */
    public usuarios!: RankingUsuario[];
}
<template>
    <ion-header>
        <IonToolbar>
            <IonTitle v-if="props.indiceReto">
                Reto: {{ props.indiceReto }} de 7
            </IonTitle>
            <IonTitle v-else>
                Pregunta
            </IonTitle>
        </IonToolbar>
    </ion-header>
    <ion-content v-if="pregunta">
        <ion-grid>
            <!-- TEXT -->
            <ion-row class="ion-padding">
                <div v-if="data.textoMejorado" v-html="pregunta.html">
                </div>
                <p v-else>
                    {{ pregunta.texto }}
                </p>
                <ion-toggle v-if="false" v-model="data.textoMejorado">Mejorar</ion-toggle>
            </ion-row>

            <!-- TEMPS -->
            <ion-row>
                <ion-col size="10">
                    <ion-progress-bar :value="porcentajeRestante"></ion-progress-bar>
                </ion-col>
                <ion-col size="2">
                    <span>{{ data.tiempoRestante.toFixed(0) }}s</span>
                </ion-col>
            </ion-row>

            <!-- IMAGEN -->
            <ion-row v-if="pregunta.image">
                <ion-col>
                    <IonSlides zoom="true">
                        <IonSlide>
                            <img :src="pregunta.image" />
                        </IonSlide>
                    </IonSlides>
                </ion-col>
            </ion-row>

            <!-- RESPOSTES -->
            <ion-row>
                <ion-list>
                    <ion-button expand="block" class="ion-text-wrap" v-for="respuesta in pregunta?.respuestas"
                        :disabled="respuesta.incorrecta ? true : false" @click="emit('clickRespuesta', respuesta.codigo)"
                        :key="respuesta.codigo">
                        <p>
                            {{ respuesta.texto }}
                        </p>
                    </ion-button>
                </ion-list>
            </ion-row>

        </ion-grid>
    </ion-content>
</template>

<script setup lang="ts">
import { Pregunta } from '@/models/Pregunta';
import { IonSlides, IonSlide, IonGrid, IonCol, IonToggle, IonRow, IonContent, IonHeader, IonList, IonButton, IonProgressBar, IonToolbar, IonTitle } from '@ionic/vue';
import { reactive, computed, onMounted } from 'vue';

const props = defineProps({
    pregunta: Pregunta,
    indiceReto: Number
})

const data = reactive({
    tiempoRestante: 60,
    inicio: Date.now(),
    textoMejorado: false
})

const emit = defineEmits<{
    (e: 'clickRespuesta', id: number): void,
    (e: 'tiempoAgotado'): void,
}>()
const porcentajeRestante = computed(() => {
    if (!props.pregunta) return 0;

    return data.tiempoRestante / parseInt(props.pregunta.tiempo);
})

onMounted(() => {
    setInterval(() => {
        const tiempoRestante = props.pregunta?.tiemporestante

        if (tiempoRestante)
            data.tiempoRestante = tiempoRestante - Math.floor((Date.now() - data.inicio)) / 1000;

        if (data.tiempoRestante <= 0) {
            data.tiempoRestante = 0
            // alert('Tiempo agotado!')
            emit('tiempoAgotado')
        }
    }, 1000);
})
</script>

<style scoped>
ion-list,
ion-item {
    --background: var(--mirial-color-light-gray) !important;
    background: var(--mirial-color-light-gray) !important;
}

ion-button {
    height: auto;
}

ion-list {
    width: 100%;
}

#container {
    text-align: center;
    position: absolute;
    left: 0;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
}

#container strong {
    font-size: 20px;
    line-height: 26px;
}

#container p {
    font-size: 16px;
    line-height: 22px;
    color: #8c8c8c;
    margin: 0;
}

#container a {
    text-decoration: none;
}
</style>
import { Partida } from '@/models/Partida'
import { application } from './application'
import { alertController, modalController } from '@ionic/vue'
import NuevaPartida from '@/views/components/NuevaPartidaView.vue'
import { Usuario } from '@/models/Usuario'
export class partidas {
    App: application

    constructor(App: application) {
        this.App = App
    }

    async Activas(): Promise<Partida[]> {
        const result = await this.App.api.get('partidas/activas')
        return result.object
    }
    async Cargar(id: number): Promise<Partida> {
        const result = await this.App.api.get('partidas/cargar?idpartida=' + id)
        return Object.assign(new Partida(), result.object)
    }
    async AceptarInvitacion(id: number): Promise<boolean> {
        const result = await this.App.api.get('partidas/aceptar?idpartida=' + id)
        return Object.assign(new Boolean(), result.object)
    }
    async Cancelar(id: number): Promise<boolean> {
        const result = await this.App.api.get('partidas/cancelar?idpartida=' + id)
        return Object.assign(new Boolean(), result.object)
    }

    async IniciarNuevaPartida(usuario: Usuario): Promise<boolean> {
        if (await this.App.partidas.confirmarIniciarNuevaPartidaCon(usuario)) {
            await this.App.juego.NuevaPartida(usuario.id)
            return true
        }
        return false
    }
    async showNuevaPartida() {
        const modal = await modalController.create(
            {
                component: NuevaPartida,
                componentProps: {
                    showHeader: true,
                    onNuevaPartidaConUsuario: async (usuario: Usuario) => {
                        modal.dismiss(usuario, 'nueva-partida')
                    },
                    onNuevaPartidaConUsuarioAleatorio: async () => {
                        modal.dismiss(null, 'nueva-partida')
                    },
                    onModoIndividual: async () => {
                        modal.dismiss(null, 'modo-individual')
                    }
                }
            }
        )

        modal.present()

        const { data, role } = await modal.onWillDismiss()


        if (role == 'nueva-partida') {
            // TODO: lanzar invitacion nueva partida
            if (!data)
                await this.App.juego.NuevaPartida(-1)
            else
                await this.App.juego.NuevaPartida(data.id)

            return data
        }
        if (role == 'modo-individual') {
            this.App.routerPush('/individual')
        }

        return null
    }
    async confirmarAceptarInvitacion(partida: Partida): Promise<boolean> {
        let result = false

        let message = '¿Deseas aceptar la invitación?'

        if (partida.pendienteAceptar == false)
            return false

        message = '¿Deseas aceptar la invitación con el usuario ' + partida.oponente.usuario + '?'

        const alert = await alertController.create({
            header: 'Aceptar invitación',
            message: message,
            buttons: [
                {
                    text: 'No'
                },
                {
                    text: 'Si',
                    handler: () => {
                        result = true
                    }
                }
            ]
        });

        alert.present();
        await alert.onDidDismiss();

        return result
    }

    async confirmarVolverAJugar(partida: Partida): Promise<boolean> {
        let result = false

        const message = '¿Deseas volver a jugar contra ' + partida.oponente.usuario + '?'

        const alert = await alertController.create({
            header: 'Volver a jugar',
            message: message,
            buttons: [
                {
                    text: 'No'
                },
                {
                    text: 'Si',
                    handler: () => {
                        result = true
                    }
                }
            ]
        });

        alert.present();
        await alert.onDidDismiss();

        return result
    }


    async confirmarIniciarReto(partida: Partida): Promise<boolean> {
        let result = false

        const message = '¿Deseas iniciar el reto contra ' + partida.oponente.usuario + '?'

        const alert = await alertController.create({
            header: 'Reto',
            message: message,
            buttons: [
                {
                    text: 'No'
                },
                {
                    text: 'Si',
                    handler: () => {
                        result = true
                    }
                }
            ]
        });

        alert.present();
        await alert.onDidDismiss();

        return result
    }
    async confirmarCancelarPartida(partida: Partida): Promise<boolean> {
        let result = false

        let message = '¿Deseas cancelar la partida?'

        if (partida.finalizada) {
            message = '¿Deseas eliminar la partida con el usuario ' + partida.oponente.usuario + '?'
        } else if (partida.pendienteAceptar || partida.aceptada === false) {
            message = '¿Deseas rechazar la invitación del usuario ' + partida.oponente.usuario + '?'
        } else if (partida.miTurno) {
            message = '¿Deseas abandonar la partida con el usuario ' + partida.oponente.usuario + '?'
        } else if (!partida.miTurno) {
            message = '¿Deseas abandonar la partida con el usuario ' + partida.oponente.usuario + '?'
        }

        const alert = await alertController.create({
            header: 'Cancelar partida',
            message: message,
            buttons: [
                {
                    text: 'No'
                },
                {
                    text: 'Si',
                    handler: () => {
                        result = true
                    }
                }
            ]
        });

        alert.present();
        await alert.onDidDismiss();

        return result
    }
    async confirmarIniciarNuevaPartidaCon(usuario: Usuario | null): Promise<boolean> {
        let result = false

        let message = '¿Deseas iniciar una nueva partida?'

        if (usuario) {
            message = '¿Deseas iniciar una nueva partida con el usuario ' + usuario.usuario + '?'
        } else {
            message = '¿Deseas iniciar una nueva partida con un usuario aleatorio?'
        }

        const alert = await alertController.create({
            header: 'Invitar a usuario',
            message: message,
            buttons: [
                {
                    text: 'No'
                },
                {
                    text: 'Si',
                    handler: () => {
                        result = true
                    }
                }
            ]
        });

        alert.present();
        await alert.onDidDismiss();

        return result
    }
    groupPartidasByEstado(partidas: Array<Partida>): Array<Array<Partida>> {
        const result = partidas.reduce((acc: any, partida) => {
            const estado = this.getEstado(partida);

            (acc[estado] = acc[estado] || []).push(partida);

            return acc;
        }, {})

        return result;
    }
    getEstado(partida: Partida): number {
        if (partida.finalizada) {
            return 3 // Finalizada
        } else if (partida.pendienteAceptar || partida.aceptada === false) {
            return 0 // Pendiente de aceptar
        } else if (partida.reto && partida.reto.pregunta) {
            return 1 // Reto te toca responder
        } else if (partida.reto && partida.reto.indice === null) {
            return 2 // Reto oponente respondiendo
        } else if (partida.miTurno) {
            return 1 // Te toca jugar
        } else if (!partida.miTurno) {
            return 2 // Turno oponente
        }

        return -1; // Error
    }

    getEstadoTexto(estado: any): string {
        switch (parseInt(estado)) {
            case 0:
                return 'Invitaciones'
            case 1:
                return 'Te toca jugar'
            case 2:
                return 'Turno oponente'
            case 3:
                return 'Finalizada'
            default:
                return 'Error'
        }
    }
}

<template>
    <img :src="getNivelIcon(nivel)">
</template>
<script setup lang="ts">
import levelIcon1 from '@/assets/level-icon-1.svg';
import levelIcon2 from '@/assets/level-icon-2.svg';
import levelIcon3 from '@/assets/level-icon-3.svg';

const props = defineProps({
    nivel: {
        type: Number,
        required: true
    }
})

function getNivelIcon(nivel: any) {
    switch (nivel) {
        case 1:
            return levelIcon1
        case 2:
            return levelIcon2
        case 3:
            return levelIcon3
        default:
            return null
    }
}
</script>
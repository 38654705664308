import { Especialidad } from "./Especialidad";
import { PreguntaRespuesta } from "./PreguntaRespuesta";

export class Pregunta {
    /**
     * Id de pregunta
     */
    public id = 0;
    /**
     * Enunciado de la pregunta
     */
    public texto = "";
    /**
     * Enunciado de la pregunta en html
     */
    public html = "";
    /**
     * Especialidad
     */
    public especialidad!: Especialidad;
    /**
     * Referencia Año-Mes de la pregunta
     */
    public referencia = "";
    /**
     * Imagen asociada (BASE64 encoded)
     */
    public image!: string | null;
    /**
     * Respuestas
     */
    public respuestas!: PreguntaRespuesta[];
    /**
     * Orden de las respuestas
     */
    public ordenrespuestas!: string;
    /**
     * Tiempo total
     */
    public tiempo!: string;
    /**
     * Tiempo restante
     */
    public tiemporestante!: number;
    /**
     * Ronda
     */
    public ronda = 0;
    /**
     * Puntos obtenidos
     */
    public puntos: number | null = 0;
    /**
     * He acertado la pregunta
     */
    public acertada: boolean | null = false;
    /**
     * Tiempo usado
     */
    public tiempousado: number | null = 0;
    /**
     * Mensaje
     */
    public mensajerespuesta: string | null = "";

    /**
     * Es diamante
     */
    public esdiamante = false;

    /**
     * Color
     */
    public color = 0;

    /**
     * Es pregunta de reto
     */
    public esReto = false;

    /**
     * Indice de la pregunta dentro del reto
     */
    public indice: number | null = 0;
}

